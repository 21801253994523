@import "typography";

.contact-us {
  display: flex;
  width: 100%;
  padding: 10rem 2rem 6rem 2rem;
  height: 100vh;

  &_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  &_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;

    h1 {
      @include extrabold;
      margin-bottom: 1rem;
    }

    p {
      @include font-bold;
      color: $color-gray;
      font-size: 24px;
      margin-bottom: 1rem;
    }
  }

  &_creds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    max-width: 500px;

    .creds_group {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;

      img {
        width: 1.5rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
      }

      p {
        @include font-bold;
        font-size: 1rem;
        color: $color-gray;

        &:hover {
          color: $color-green;
        }
      }
    }
  }
}

.contact_form {
  background: #fff;
  max-height: max-content;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    @include bold;
    margin-bottom: 1rem;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form_group {
    display: flex;
    margin: 0.5rem 1rem;
    position: relative;

    input,
    textarea {
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      background-color: rgba(0, 0, 0, 0.05);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    input[type="tel"],
    input[type="email"] {
      width: 48%;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
    }

    textarea {
      height: 100px;
      resize: none;
    }
  }

  .submit_btn {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-bold;
    width: 60%;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
    }
    &:active {
      transform: scale(0.98);
    }
  }
}

@media (max-width: 1024px) {
  .contact_form {
    h3 {
      font-size: 24px;
    }
  }

  .contact-us {
    &_detail {
      max-width: 400px;
      p {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 992px) {
  .contact-us {
    height: fit-content;
    &_container {
      flex-direction: column-reverse;
      gap: 2rem;
    }

    &_detail {
      max-width: 100%;
      align-items: center;
      p {
        text-align: center;
        font-size: 20px;
      }
    }

    &_creds {
      max-width: 70%;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .contact-us {
    &_detail {
      p {
        font-size: 18px;
      }
    }

    &_creds {
      max-width: 100%;
    }
  }

  .contact_form {
    .submit_btn {
      width: 70%;
    }
  }
}
