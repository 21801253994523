@import "typography";

.calculator_page {
  padding: 10rem 2rem;
  h1 {
    @include extrabold;
    color: $color-white;
    margin-bottom: 2rem;
  }
}

.current_val {
  @include font-semibold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 1rem;
}

input[type="range"] {
  &:active {
    outline: none;
  }
}

.form-container {
  width: 60vw;
  margin: 0 auto;
  padding: 1.5rem;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(133, 133, 133, 0.1) 100%
  );
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

.form-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;

  &-top {
    display: flex;
    align-items: center;

    label {
      // color: $color-white;

      margin-top: 1rem;
      margin-right: 1rem;
    }
    span {
      font-size: 1rem;
      @include font-semibold;
      color: $color-white;
    }
  }

  .form-label {
    display: block;
    color: $color-black;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .current-val {
    display: block;
    max-width: 65px;
    border: 0.5px solid $color-green;
    border-radius: $border-radius;
    padding: 0 0.5rem;
    background: transparent;
    font-size: 0.875rem;
    color: $color-green;
    margin-top: 0.5rem;
  }

  .form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01);
    outline: none;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:active {
      border: none;
    }
  }
}

.range-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;

  .range-min,
  .range-max {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    color: black; // Grayish text color
    padding: 0 0.5rem;
  }

  .range-min {
    margin-right: 0.5rem; // Space before the range input
  }

  .range-max {
    margin-left: 0.5rem; // Space after the range input
  }
}

.submit-btn,
.reset-btn {
  margin-top: 1rem;
  width: 100%;
  background-color: $color-green;
  color: white;
  font-weight: bold;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $color-gray;
  }
}

.results-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f7fafc;
  border-radius: 0.5rem;
}

.results-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.results-text {
  margin-bottom: 0.5rem;
  color: #4a5568;
}

.custom-range {
  /* General Styling for the Range Input */
  -webkit-appearance: none; /* For Chrome and Safari */
  max-width: 400px;
  height: 10px;
  background: #d3d3d3; /* Track color */
  border-radius: 5px;
  outline: none;

  /* Styling for Chrome, Safari, Edge */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-green; /* Thumb color */
    cursor: pointer;
    border: 2px solid $color-green; /* Border around thumb */
  }

  /* Styling for Firefox */
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 5;
    background: $color-green; /* Thumb color */
    cursor: pointer;
    border: 2px solid $color-green; /* Border around thumb */
  }

  /* For IE/Edge */
  &::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-green; /* Thumb color */
    cursor: pointer;
    border: 2px solid $color-green; /* Border around thumb */
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Hide for Firefox */
}
