@import "typography";

.review_card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
  max-width: 500px;
  min-height: 230px;
  padding: 2rem;
  border-radius: 10px;
  margin: 0 auto;

  &_name {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }
    h3 {
      @include font-medium;
      font-size: 18px;
      margin-left: 0.5rem;
    }
  }

  &_position {
    @include font-medium;
    font-size: 14px;
    word-spacing: 3px;
    color: $color-green;
    margin: 0.5rem 0;
  }

  &_text {
    @include font-semibold;
    font-size: 16px;
    color: $color-gray;
    margin-top: 1rem;
  }
}

.odd_background {
  background: $card-gradient;
}

.even_background {
  background: $card-gradient-reverse;
}

@media (max-width: 1280px) {
  .review_card {
    padding: 1rem;
    min-height: 180px;

    &_name {
      h3 {
        font-size: 16px;
      }
    }

    &_position {
      font-size: 12px;
    }

    &_text {
      font-size: 14px;
    }
  }
}

@media (max-width: 992px) {
  .review_card {
    padding: 0.5rem;
    min-height: 215px;
  }
}

@media (max-width: 778px) {
  .review_card {
    padding: 0.5rem;
    min-height: 150px;
  }
}

@media (max-width: 576px) {
  .review_card {
    margin-bottom: 1rem;
  }
}
