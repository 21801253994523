@import "typography";

.about-wrapper {
  padding: 7rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    @include extrabold;
    color: $color-gray;
    margin-bottom: 1rem;
  }

  h2 {
    @include bold;
    font-size: 1.8rem;
    color: $color-green;
    margin-bottom: 1rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    @include bold;
    color: $color-green;
    margin-bottom: 0.5rem;
    align-self: start;

    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }

  p {
    @include font-semibold;
    color: $color-gray;
    line-height: 1.6;
    margin-bottom: 1rem;

    // @media (max-width: 768px) {
    //   font-size: 0.9rem;
    // }
  }

  .contact-link {
    border: 1px solid $color-black;
    padding: 1rem;
    @include font-semibold;
    color: $color-white;
    background-color: $color-black;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .about-image {
    align-self: center;
    width: 60%;
    border-radius: 10px;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
