@import "typography";

.about {
  padding: 2rem;
  width: 100%;
  height: 100%;

  &_container {
    margin: 0 auto;
    display: flex;
  }

  &_images {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_text {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h2 {
      @include extrabold;
      color: $color-green;
    }

    p {
      @include font-medium;
      font-size: 24px;
      color: $color-gray;
    }

    ul {
      align-self: center;
      margin: 4rem auto;
      color: $color-green;
      @include font-bold;
      line-height: 2rem;
      font-size: 18px;
      li {
        img {
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
    }

    .button {
      cursor: pointer;
      text-align: center;
      width: 30%;
      padding: 1rem;
      margin: 0 auto;
      color: $color-white;
      max-width: 200px;
      @include font-bold;
      border-radius: $border-radius;
      border: none;
      background-color: $color-green;
    }
  }

  .main-image {
    // width: 100%;
    // height: 100%;
    margin: 2rem;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .side-image {
    position: absolute;
    // width: 200px;
    top: 60%;
    left: 55%;
  }
}

@media screen and (max-width: 1400px) {
  .about {
    &_container {
    }
    &_text {
      padding: 1rem;
      max-width: 65%;
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 22px;
      }
      ul {
        margin: 2rem auto;
      }
    }
  }

  .main-image {
    min-width: 300px;
    min-height: 450px;
  }
  .side-image {
    width: 250px;
  }
}

@media screen and (max-width: 992px) {
  .about {
    width: 100%;
    padding: 0;

    &_container {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &_images {
      display: none;
    }
    &_text {
      padding: 0;
      padding-bottom: 1.5rem;
      min-width: 80%;
      h2 {
        font-size: 28px;
        text-align: center;
      }
      p {
        font-size: 22px;
        text-align: center;
      }
      ul {
        margin: 2rem auto;
      }
      .button {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    &_text {
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 480px) {
  .about {
    &_text {
      .button {
        width: 65%;
      }
    }
  }
}
