@import "typography";

.changeback {
  background-color: #eaeaea;
}

.swiper {
  position: relative; /* Ensure position is set to relative */
  width: 100%;
  margin: 0 auto;
  // z-index: -1;
  overflow: hidden;
  // padding: 1rem;
  cursor: pointer;

  &_container {
    padding: 1rem;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &_slide {
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 100%;
    width: 100%;
  }

  &_text {
    padding-right: 2rem;

    span {
      font-size: 16px;
      color: rgba($color: #000000, $alpha: 0.7);
    }

    h1 {
      font-size: 28px;
      font-weight: bold;
      color: #000;
    }
  }

  &_num {
    font-size: 60px;
    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.5);
  }
}

@media (max-width: 1280px) {
  .swiper {
    &_text {
      span {
        font-size: 14px;
      }
      h1 {
        font-size: 24px;
      }
    }

    &_num {
      font-size: 50px;
    }
  }
}

@media (max-width: 992) {
  .swiper {
    &_text {
      span {
        font-size: 12px;
      }
      h1 {
        font-size: 20px;
      }
    }

    &_num {
      font-size: 40px;
    }
  }
}

@media (max-width: 768px) {
  .swiper {
    &_text {
      span {
        font-size: 10px;
      }
      h1 {
        font-size: 16px;
      }
    }

    &_num {
      font-size: 30px;
    }
  }
}
