.results-container {
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
  }
  
  .results-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .results-table th, .results-table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .results-table th {
    background-color: #4CAF50;
    color: white;
  }
  
  .results-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .results-table td {
    font-size: 16px;
  }
  
  .reset-btn {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 30px auto 0;
    background-color: #4CAF50;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .reset-btn:hover {
    background-color: #45a049;
  }
  
  .pdf-btn {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 20px auto 0;
    background-color: #4CAF50;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pdf-btn:hover {
    background-color: #45a049;
  }
  