@import "typography";

.partners {
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &_title {
    margin: 1rem auto;
    margin-bottom: 2rem;
    color: $color-green;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    h2 {
      font-size: 22px;
      @include font-bold;
      color: $color-green;
      z-index: 1;
      margin: 0 1rem;
    }
    img {
      max-width: 50px;
    }

    img:nth-child(2) {
      rotate: -1deg;
    }
  }

  .logo_slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 150px;
    height: 80px;
  }

  .custom-roll {
    // padding: 0 0 2rem 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 992px) {
  .partners {
    &_title {
      h2 {
        font-size: 18px;
      }

      img {
        max-width: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .partners {
    &_title {
      h2 {
        font-size: 16px;
      }

      img {
        max-width: 30px;
      }
    }
  }
}

@media (max-width: 576px) {
  .partners {
    &_title {
      h2 {
        font-size: 14px;
      }

      img {
        max-width: 20px;
      }
    }
  }
}
