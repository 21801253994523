// _typography.scss

$font-family-base: "Plus Jakarta Sans", sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$color-green: #0f6828;
$color-white: #fff;
$color-black: #000;
$color-gray: rgba(0, 0, 0, 0.5);
$gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
$gradient-reverse: linear-gradient(
  to top,
  rgba(0, 0, 0, 0.2),
  rgba(0, 0, 0, 0.1)
);

$card-gradient: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0.25) 0%,
  rgba(133, 133, 133, 0.1) 100%
);
$card-gradient-reverse: linear-gradient(
  to top,
  rgba(0, 0, 0, 0.25) 0%,
  rgba(133, 133, 133, 0.1) 100%
);

$border-radius: 5px;

@mixin font-base {
  font-size: 14px;
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
}

@mixin font-medium {
  font-size: 16px;
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
}

@mixin font-semibold {
  font-size: 16px;
  font-family: $font-family-base;
  font-weight: $font-weight-semibold;
}

@mixin font-bold {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

@mixin font-extrabold {
  font-family: $font-family-base;
  font-weight: $font-weight-extrabold;
}

@mixin h1 {
  @include font-bold;
  font-size: 54px;
  line-height: 1.2;
}

@mixin bold {
  @include font-bold;
  font-size: 28px;
}

@mixin extrabold {
  @include font-extrabold;
  font-size: 44px;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 576px) {
    font-size: 22px;
  }
}
