@import "typography";

.hero {
  &_container {
    transition: 1s ease-in-out, visibility 1s ease-in-out;
  }

  &_slide {
    position: relative; // Set position to relative to position content absolutely within
    width: 100%;
    height: 80vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80vh;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &_form {
    position: absolute;
    top: 50%;
  }

  &_content {
    position: absolute; // Position the content absolutely within the slide
    top: 15%;
    left: 5%;
    right: 15%;
    color: #fff;
    z-index: 2;
    display: flex;

    h1 {
      @include bold;
    }

    p {
      @include extrabold;
    }

    .btn {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      background-color: #000;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      @include font-bold;
      letter-spacing: 2px;
      border: 1px solid #fff;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #444;
      }
    }
  }

  &_buttons {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    img {
      min-width: 35px;
      height: 35px;
    }
  }

  &_main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-left: 2rem;
  }

  &_plays {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    img {
      width: 50px;
      height: 50px;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }

    .play_button {
      margin-left: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.2); // Apply scaling effect on hover
        // margin-left: 2rem;
      }

      &:active {
        transform: scale(1.1); // Slightly scale down on click if desired
        // margin-left: 2rem;
      }
      span {
        margin-bottom: 0.4rem;
      }
    }

    @include font-extrabold;
  }

  &_container {
    margin-top: 4rem;
  }
}

.youtube-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  z-index: 2000;
  padding: 2rem;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.75);
}

.modal-content {
  iframe {
    width: 560px;
    height: 350px;
  }
}

.close-modal {
  background: white;
  padding: 0.5rem;
  border-radius: 60%;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 1200px) {
  .hero_container {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1084px) {
  .hero {
    &_content {
      top: 18%;

      h1 {
        font-size: 24px;
      }

      p {
        font-size: 36px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    height: 40vh;
    overflow: hidden;
    &_content {
      top: 10%;
      left: 2%; // Adjust left margin for smaller screens
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }
    }

    &_main {
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero {
    height: 45vh;
    overflow: hidden;
    &_container {
      margin-top: 2.4rem;
    }

    &_content {
      top: 10%;
      left: auto;
      right: auto;

      h1 {
        font-size: 22px;
      }

      p {
        font-size: 30px;
      }

      .btn {
        padding: 0.5rem 1rem;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    &_main {
      margin-left: 0;
      margin-right: 2rem;
    }

    &_buttons {
      img {
        width: 30px;
        height: 30px;
      }
    }

    &_plays {
      img {
        width: 45px;
        height: 45px;
        margin-right: 0.3rem;
      }

      .play_button {
        margin-left: 1.5rem;
      }
    }
  }
  .modal-content {
    iframe {
      width: 450px;
      height: 260px;
    }
  }
}

@media screen and (max-width: 576px) {
  .hero {
    height: 50vh;
  }
  .modal-content {
    iframe {
      width: 300px;
      height: 200px;
    }
  }
}

@media (max-width: 478px) {
  .hero {
    height: 55vh;
    overflow: hidden;
    &_slide {
      width: 100%;
    }

    &_buttons {
      padding: 0.5rem;
    }

    &_plays {
      margin-top: 0;
      position: absolute;
      top: 130%;
      flex-direction: column;
      align-items: center;
      .btn {
        font-size: 14px;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 0.3rem;
      }

      .play_button {
        margin-left: 0;
      }
    }

    &_content {
      width: 100%;
      top: 10%;
      h1 {
        font-size: 20px;
        width: 120%;
        text-align: center;
      }

      p {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 390px) {
  .hero {
    height: 55vh;
    &_content {
      // position: relative;
      padding: 0;
      margin: 0;
      top: 10%;
      h1 {
        font-size: 18px;
        padding: 0;
      }

      p {
        padding: 0;
        font-size: 22px;
      }
    }
  }
}
