.sss {
  padding: 7rem 2rem 0 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1440px) {
  .sss {
    padding: 5rem 2rem 0 2rem;
  }
}
