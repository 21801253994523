@import "typography";

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  z-index: 1000;
  @include font-semibold;
  font-size: 20px;
}

.scroll-to-top.show {
  display: block;
}
