@import "typography";

.privacy-policy {
  padding: 8rem;
  padding-bottom: 4rem;
  color: $color-gray;
  h1 {
    margin-top: 2rem;
    @include font-extrabold;
    font-size: 18px;
  }
  h3 {
    @include font-bold;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .privacy-policy {
    h1 {
      margin-top: 0;
    }
    padding: 6rem 2rem 4rem 2rem;
  }
}
