@import "typography";

$green-color: $color-green; // Your base green color
$hover-green: #228b22; // Hover green color
// $footer-padding: 40px 20px; // Footer padding
$footer-max-width: 1200px; // Max width for the footer container

.footer {
  background: $gradient;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-logo {
    img {
      position: absolute;
      top: 0;
      left: 5%;
      width: 150px;
    }

    address {
      text-align: center;
      font-style: normal;
      color: $color-gray;
      margin-bottom: 2rem;
      max-width: 400px;

      p {
        margin: 5px 0;
        &:hover {
          color: $hover-green;
        }
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $footer-max-width;

    .footer-column {
      flex: 1;
      text-align: center;

      h4 {
        @include font-medium;
        margin-bottom: 20px;
        font-size: 1.2rem;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            @include font-medium;
            color: $green-color;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
              color: $hover-green;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    @include font-medium;
    margin-top: 2rem;
    text-align: center;
    color: $green-color;
    font-size: 1rem;

    p {
      margin: 0;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    &-logo {
      img {
        width: 120px;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &-logo {
      img {
        width: 100px;
      }
    }

    address {
      p {
        font-size: 14px;
      }
    }
    .footer-links {
      .footer-column {
        h4 {
          font-size: 1rem;
        }
        ul {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }

    .footer-bottom {
      font-size: 12px;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 0;
    &-logo {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-items: start;

      img {
        position: relative;
        left: 0;
        align-self: start;
      }

      address {
        text-align: start;
      }
    }
    .footer-links {
      flex-direction: column;

      .footer-column {
        margin-bottom: 1rem;
        h4 {
          margin-bottom: 10px;
        }
      }
    }

    .footer-bottom {
      margin-top: 0;
      padding: 0 2rem;
      margin-bottom: 1rem;
    }
  }
}
