@import "typography";

.product_page {
  display: flex;
  padding: 10rem 4rem;
  // justify-content: center;

  &_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &_item {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    background: transparent;

    h1 {
      @include font-bold;
      font-size: 36px;
      color: $color-gray;
      margin-bottom: 2rem;
    }
    img {
      width: 500px;
      height: 350px;
      padding: 0;
      border-radius: $border-radius;
    }

    p {
      @include font-bold;
      font-size: 24px;
      color: $color-gray;
    }

    h3 {
      margin-top: 2rem;
      @include font-bold;
      font-size: 24px;
      color: $color-black;
    }
  }

  &_list {
    // flex: 2;
    margin-top: 5rem;

    h3 {
      margin-top: 1rem;
      @include font-bold;
      font-size: 24px;
      color: $color-gray;
    }

    .product_list {
      width: 200px;
      border-radius: $border-radius;

      li {
        margin-top: 1rem;

        &:hover {
          border-bottom: 1px solid white;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .product_page {
    padding: 7rem 3rem;

    &_item {
      img {
        width: 300px;
        height: 150px;
      }
    }
  }
}

@media (max-width: 992px) {
  .product_page_item {
    h1 {
      font-size: 28px;
    }

    P {
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  .product_page {
    padding: 5rem 2rem;

    &_container {
      flex-direction: column;
    }

    &_list {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .product_page_item {
    h1 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }

  .product_page_list {
    width: 60vw;
    h3 {
      font-size: 20px;
    }

    .product_list {
      width: 60vw;
    }
  }
}
