@import "typography";

.products_page {
  padding: 10rem 2rem;
  h1 {
    text-align: center;
    @include extrabold;
    color: $color-gray;
    margin-bottom: 2rem;
  }

  &_container {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
  }

  &_item {
    padding: 2rem 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent; /* Hidden border initially */
    border-radius: $border-radius; /* No rounded corners initially */
    transition: border 0.4s ease, border-radius 0.4s ease;

    &:hover {
      border: 2px solid #000; /* Border becomes visible */
      border-radius: 20px; /* Rounded corners appear */
    }
  }
}
