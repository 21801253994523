@import "typography";

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  background-color: #000000;
  color: #ffffff;

  .top-contact {
    margin-left: 15%;
    display: flex;
    @include font-base;

    .contact-info {
      display: flex;
      margin-left: 3rem;
      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
  }
  .top-social {
    display: flex;
    margin-right: 5%;
    @include font-base;

    .icons {
      display: flex;
      margin-left: 1rem;
      align-items: center;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 1rem;
      }
    }
  }
}

.top-section__logo {
  position: relative;
  z-index: 106;

  img {
    position: fixed;
    left: 5%;
    top: 0;
  }
}

@media screen and (max-width: 1200px) {
  .top-section {
    .top-contact {
      font-size: 0.75rem;
      // margin-left: 20%;
    }
    .top-social {
      font-size: 0.75rem;

      .icons {
        .icon {
          width: 18px;
          height: 18px;
          margin-right: 0.75rem;
        }
      }
    }
  }

  .top-section__logo {
    img {
      width: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-section {
    .top-contact {
      font-size: 0.5rem;
    }
    .top-social {
      font-size: 0.5rem;

      .icons {
        .icon {
          width: 12px;
          height: 12px;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .top-section__logo {
    img {
      width: 100px;
    }
  }
}

@media screen and (max-width: 568px) {
  .top-section {
    .top-social {
      .icons {
        margin: 0;
        .icon {
          margin-right: 0.25rem;
        }
      }
    }
  }

  // .top-section__logo {
  //   display: none;
  // }
}

@media screen and (max-width: 480px) {
  .top-section {
    justify-content: center;
    .top-contact {
      // font-size: 0.4rem;
      // width: 100%;
      // justify-content: space-around;

      // .contact-info {
      //   margin: 0;
      //   &:nth-child(2) {
      //     margin: 0;
      //   }
      display: none;
      // }
    }
    .top-social {
      // display: none;
      // align-self: center;
      margin-left: 25%;
    }
  }
}
