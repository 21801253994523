/* Popup container */
.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  padding: 15px;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show popup */
.popup.show {
  opacity: 1;
  transform: translateY(0);
}

/* Success popup */
.popup.success {
  background-color: #4caf50; /* Green */
}

/* Error popup */
.popup.error {
  background-color: #f44336; /* Red */
}

/* Popup message */
.popup_message {
  margin-left: 10px;
}

.popup_icon {
  font-size: 1.5rem;
}
