@import "typography";

.sticky {
  position: fixed;
  top: 0;
}

.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 105;
  position: fixed;
  width: 100%;

  &_container {
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.9rem;
  }

  &_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    position: fixed;
  }

  &_logo {
    img {
      display: none;
    }
  }

  &_link {
    color: #000;
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    @include font-semibold;

    &:nth-child(5) {
      @include font-extrabold;
      margin-left: 3rem;
    }

    &:hover {
      background-color: #cacbcc;
      border-radius: 0.25rem;
    }

    &:active {
      background-color: #cacbcc;
      border-radius: 0.25rem;
    }
  }

  &_dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
    }

    .dropdown_content {
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: absolute;
      padding: 1rem;
      width: max-content;
      top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include font-medium;

      .dropdown_link {
        color: #000;
        padding: 0.5rem 1rem;
        // margin: 2rem auto;
        &:hover {
          background-color: #cacbcc;
          border-radius: 0.25rem;
        }
      }
    }
  }

  &_button {
    background-color: #000;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    @include font-semibold;

    img {
      margin-left: 0.5rem;
    }

    &:hover {
      background-color: #333;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  &_icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  &_toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  /* Styles for desktops */
  .navbar {
    &_container {
      height: 2.9rem;
    }

    &_link {
      font-size: 14px;
    }

    &_dropdown {
      .dropdown_content {
        font-size: 14px;
      }
    }

    &_button {
      font-size: 14px;
      padding: 0.3rem 0.5rem;

      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}

@media (min-width: 767.98px) and (max-width: 992px) {
  /* Styles for tablets */
  .navbar {
    &_dropdown {
      .dropdown_content {
        font-size: 12px;
      }
    }

    &_link {
      padding: 0.5rem;
      font-size: 12px;

      &:nth-child(5) {
        margin-left: 1.5rem;
      }
    }

    &_button {
      font-size: 12px;
      padding: 0.3rem 0.5rem;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

@media (min-width: 615px) and (max-width: 768px) {
  /* mobile landscape */
  .navbar {
    &_container {
      height: 2.4rem;
    }

    &_link {
      padding: 0.3rem;
      font-size: 10px;
      &:nth-child(5) {
        margin-left: 0;
      }
    }

    &_dropdown {
      .dropdown_content {
        font-size: 10px;
      }
    }

    &_button {
      padding: 0.3rem;
      font-size: 10px;
      img {
        height: 15px;
        width: 15px;
      }
    }
  }
}

@media (max-width: 614px) {
  /* mobiles */

  .navbar {
    &_container {
      justify-content: flex-end;
      height: 2.4rem;
    }

    &_link {
      &:nth-child(5) {
        margin: 0;
      }
    }

    &_dropdown {
      .dropdown_content {
        font-size: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        top: 2rem;
      }
    }

    &_menu {
      margin-left: 0;
      position: fixed;
      top: 2.3rem;
      left: 0;
      right: 0;
      background-color: white;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      padding-top: 3rem;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;

      &.active {
        transform: translateX(0);
      }
    }
    &_toggle {
      display: block;
    }
  }

  .home-edition {
    .navbar_menu {
      top: 4rem;
      padding: 1rem;
    }
  }

  .sticky {
    .navbar_menu {
      top: 2rem;
      padding-top: 3rem;
    }
  }
}
