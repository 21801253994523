@import "typography";

.ytCarousel {
  width: 100%;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 1rem;
    // background: transparent;

    h1 {
      @include extrabold;
      color: $color-gray;
      margin: 1rem auto;
    }

    .video-container {
      background: transparent;
      display: flex;
      justify-content: center;
      padding: 1rem;
      margin-bottom: 2rem;

      iframe {
        width: 300px;
        height: 150px;
        padding: 0;
        border-radius: $border-radius;
      }
    }
  }
}
