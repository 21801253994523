@import "typography.scss";

.product_card {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 1rem;
  max-width: 350px;
  height: 100%;
  img {
    align-self: center;
    min-width: 200px;
    max-height: 150px;
    height: 50%;
  }

  &_name {
    overflow: hidden;
    height: 35px;
    color: $color-green;
    @include font-extrabold;
    font-size: 22px;
  }

  &_desc {
    height: 55px;
    @include font-medium;
    color: $color-gray;
  }

  &_link {
    @include font-semibold;
    color: $color-green;
    margin-top: 0.5rem;
  }
}

.products {
  background: $card-gradient-reverse;
  // text-align: center;

  &_heading {
    text-align: center;
    padding-top: 2rem;
    color: $color-gray;
    @include extrabold;
  }

  &_container {
    justify-content: center;
    display: flex;
    padding: 2rem;
  }

  &_list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &_arrow {
    cursor: pointer;
    color: $color-green;
    width: 50px;
    z-index: 2;
    background-color: aqua;
  }
}

@media (max-width: 992px) {
  .product_card {
    margin: 1rem;
    &_name {
      font-size: 20px;
    }

    &_link {
      font-size: 14px;
    }
  }

  .products {
    &_heading {
      font-size: 28px;
    }
  }
}
