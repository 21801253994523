@import "typography";

.review_swiper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_title {
    margin: 1rem auto;
    color: $color-green;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      font-size: 22px;
      @include font-bold;
      color: $color-green;
      z-index: 1;
      margin: 0 1rem;
    }
    img {
      max-width: 50px;
    }

    img:nth-child(2) {
      rotate: -1deg;
    }
  }

  &_heading {
    @include extrabold;
    color: $color-gray;
    margin: 0 auto;
  }

  &_container {
    margin: 0 auto;
    background-color: transparent;
    // display: flex;
    max-width: 1200px;
  }

  &_slide {
    margin: 2rem auto;
    padding: 1rem;
  }
}

@media (max-width: 992px) {
  .review_swiper {
    &_title {
      h2 {
        font-size: 18px;
      }

      img {
        max-width: 40px;
      }
    }

    &_heading {
      font-size: 28px;
    }
  }
}

@media (max-width: 768px) {
  .review_swiper {
    &_title {
      h2 {
        font-size: 16px;
      }

      img {
        max-width: 30px;
      }
    }

    &_heading {
      font-size: 22px;
    }

    &_slide {
      padding: 0.5rem;
      margin: 1rem auto;
    }
  }
}

@media (max-width: 576px) {
  .review_swiper {
    &_title {
      h2 {
        font-size: 14px;
      }

      img {
        max-width: 20px;
      }
    }

    &_heading {
      font-size: 18px;
    }

    &_slide {
      padding: 0.5rem;
      margin: 1rem auto;
    }
  }
}
