@import "typography";

.faq-container {
  width: 100%;
  max-width: 1200px;
  color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    @include extrabold;
    color: $color-gray;
    align-self: start;
  }

  .faq-list {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    .faq-item {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%;
      box-shadow: inset;
      border-radius: $border-radius;
      margin-bottom: 15px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-top: 2rem;
      }

      &.active {
        box-shadow: none;
        .faq-answer {
          max-height: 200px;
          padding: 10px;
          transition: max-height 0.1s ease-out;
        }
      }

      .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        font-weight: bold;
        color: black;
        @include font-semibold;

        .arrow {
          max-width: 32px;
          transition: transform 0.1s ease-in;
          &.down {
            transform: rotate(180deg);
          }
          &.up {
            transform: rotate(0deg);
          }
        }
      }

      .faq-answer {
        @include font-semibold;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 100%
        );
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        max-height: 0;
        padding: 0;
        transition: max-height 0.1s ease;
        overflow: hidden;

        p {
          margin: 0;
          padding: 10px 15px;
          color: #666;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding-top: 20px;

    h2 {
      font-size: 20px;
    }

    .faq-list {
      .faq-item {
        .faq-question {
          font-size: 14px;
          .arrow {
            max-width: 24px;
          }
        }

        .faq-answer {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .faq-container {
    h2 {
      font-size: 18px;
    }

    .faq-list {
      .faq-item {
        .faq-question {
          font-size: 12px;
          .arrow {
            max-width: 20px;
          }
        }

        .faq-answer {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
