@import "typography";

.contact_home {
  z-index: 100;
  position: absolute;
  top: 52%;
  right: 5%;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    @include bold;
    margin-bottom: 1rem;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form_group {
    display: flex;
    margin: 0.5rem 1rem;
    position: relative;

    input,
    textarea {
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      background-color: rgba(0, 0, 0, 0.05);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    input[type="tel"],
    input[type="email"] {
      width: 48%;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
    }

    textarea {
      height: 100px;
      resize: none;
    }
  }

  .submit_btn {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-bold;
    width: 60%;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 24px;
    }
  }

  @media (max-width: 992px) {
    width: 100%;
    box-shadow: none;
    position: relative;
    top: 0;
    right: 0;
    margin: 2rem auto;
  }

  @media (max-width: 768px) {
  }
}
