@import "typography";

.ctn {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30vh; /* Adjust based on your need */
  background: url("../assets/Inquiry.png");
  width: 100%;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.12); /* Dark shadow overlay */
}

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  text-align: center;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  color: white;
  @include font-bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  // font-style: bold;
}

.cta-button {
  background-color: transparent;
  border: 2px solid white;
  border-radius: $border-radius;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* Button shadow */
}

.cta-button:hover {
  background-color: white;
  color: black;
}

.cta-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Focus state */
}

@media (max-width: 992px) {
  h2 {
    font-size: 1.2rem;
  }

  .cta-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 1rem;
  }
  .cta-button {
    padding: 7.5px 15px;
  }
}

@media (max-width: 486px) {
  .ctn {
    height: 50vh;
  }
  .button-container {
    flex-direction: column;
    justify-content: space-around;
  }
}
